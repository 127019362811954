<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        At 879 K,
        <stemble-latex content="$\text{K}_\text{eq}$" />
        is
        <number-value :value="Keq" />
        for the following reaction:
      </p>

      <p class="mb-4 pl-8">
        <chemical-latex content="2A(g) <=> B(g) + C(g)" />
      </p>

      <p class="mb-2">
        a) If gas A at
        <number-value :value="PAi" unit="\text{bar}" />
        is injected into a container at 879 K, determine the partial pressure of B once the system
        reaches equilibrium. You should assume that the equilibrium partial pressure of A is equal
        to its initial partial pressure.
      </p>

      <calculation-input
        v-model="inputs.PB"
        class="mb-5"
        prepend-text="$\text{P}_\text{B}:$"
        append-text="$\text{bar}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Determine the actual partial pressure of A once the system reaches equilibrium WITHOUT
        using the assumption that was used in part a).
      </p>

      <calculation-input
        v-model="inputs.PAeq"
        class="mb-5"
        prepend-text="$\text{P}_\text{A,eq}:$"
        append-text="$\text{bar}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) What is the percent error in the equilibrium partial pressure of A that is introduced by
        the assumption used in part a)?
      </p>

      <calculation-input
        v-model="inputs.pctErrorA"
        class="mb-0"
        prepend-text="$\%\text{ Error}:$"
        append-text="$\%$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'Question461',
  components: {
    LatexNumber,
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        PB: null,
        PAeq: null,
        pctErrorA: null,
      },
    };
  },
  computed: {
    Keq() {
      return this.taskState.getValueBySymbol('Keq').content;
    },
    PAi() {
      return this.taskState.getValueBySymbol('PAi').content;
    },
  },
};
</script>
